import Categorias from "./Categorias";
import Ofertas from "./Ofertas";

function Inicio() {

    return (
        <div style={{}}>
            <Categorias />
            <Ofertas />
        </div>
    )
}

export default Inicio;