import "./App.css";
import { HashRouter, Route, Routes, Navigate } from "react-router-dom";
import Navegacion from "./components/Navegacion";
import Inicio from "./components/Inicio";
import Catalogo from "./components/Catalogo";
import { CarritoContextProvider } from "./context/CarritoContext";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <CarritoContextProvider>
        <HashRouter>
          <Navegacion/>
          <div className="divBody">
            <Routes>
              <Route path="/home" element={<Inicio />} />
              <Route path="/catalogo" element={<Catalogo/>} />
              <Route path="*" element={<Navigate to="/home" replace />} />
            </Routes>
          </div>
          <Footer/>
        </HashRouter>
      </CarritoContextProvider>
    </div>
  );
}

export default App;
