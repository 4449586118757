import { Navbar, Container, Nav } from 'react-bootstrap';
import logo from "../images/samer.jpg";
import Dropdown from 'react-bootstrap/Dropdown';
import Carrito from './Carrito';
import "../Texto.css";

function Navegacion() {
  return (
    <>
      <Navbar variant="dark" style={{ backgroundColor: '#137EB8', position: 'sticky', top: 0, zIndex: 1000 }}>
        <Container>
          <img
            src={logo}
            alt=""
            style={{
              marginRight: '10px', height: '60px', width: '60px', borderRadius: '70%', position: 'relative', top: '-4px',
              transform: "scale(1.1)"
            }}
          />
          <Navbar.Brand href="#home" style={{ marginBottom: -10, fontSize: '160%', fontWeight: 650 }} className="roboto-titulo">
            Samer
            <br />
            <small style={{ fontSize: '65%', position: 'relative', top: '-15px' }}>Distribuidora</small>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse id="responsive-navbar-nav">

            {window.innerWidth < 403 ? (
              <Dropdown>
                <Dropdown.Toggle style={{ backgroundColor: '#E69201', marginRight: '10px', marginLeft: '10px' }} id="dropdown-basic">
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#home" style={{ marginBottom: 0, fontSize: '115%', color: 'black' }}>Inicio</Dropdown.Item>
                  <Dropdown.Item href="#catalogo?category=empty" style={{ marginBottom: 0, fontSize: '115%', color: 'black' }}>Catálogo</Dropdown.Item>
                </Dropdown.Menu>
                <Carrito />
              </Dropdown>

            ) : (
              <Nav className="navbar navbar-expand-lg" style={{ marginLeft: 'auto' }} className="roboto-texto">
                <Nav.Link href="#home" style={{ marginBottom: 0, fontSize: '115%', color: 'white' }}>Inicio</Nav.Link>
                <Nav.Link href="#catalogo?category=empty" style={{ marginBottom: 0, fontSize: '115%', color: 'white' }}>Catálogo</Nav.Link>
                <Carrito />
              </Nav>
            )}

          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Navegacion;
