const storedCart = JSON.parse(window.localStorage.getItem("cart"));

export const types = {
  ADD_TO_CART: "ADD_TO_CART",
  REMOVE_FROM_CART: "REMOVE_FROM_CART",
  REMOVE_ALL_FROM_CART: "REMOVE_ALL_FROM_CART",
  CLEAR_CART: "CLEAR_CART",
};

const isValidArray = Array.isArray(storedCart);

export const carritoInicial = {
  carrito: isValidArray ? storedCart : [],
  total: isValidArray
    ? storedCart.reduce((suma, item) => suma + item.precio * item.cantidad, 0)
    : 0,
};

export const updateLocalStorage = (carrito) => {
  window.localStorage.setItem("cart", JSON.stringify(carrito));
};

const actualizarEstado = {
  [types.ADD_TO_CART]: (state, action) => {
    const { id, nombre, precio, imagen, categoria } = action.payload;
    const itemIndex = state.carrito.findIndex((item) => item.id === id);
    if (itemIndex >= 0) {
      const nuevoCarrito = structuredClone(state.carrito);
      nuevoCarrito[itemIndex].cantidad += 1;
      const nuevoTotal = state.total + precio;

      const nuevoEstado = {
        ...state,
        carrito: nuevoCarrito,
        total: nuevoTotal,
      };
      updateLocalStorage(nuevoEstado);
      return nuevoEstado;
    } else {
      const productoNuevo = {
        id,
        nombre,
        precio,
        cantidad: 1,
        imagen,
        categoria,
      };

      const nuevoEstado = {
        ...state,
        carrito: [...state.carrito, productoNuevo],
        total: state.total + precio,
      };
      updateLocalStorage(nuevoEstado);
      return nuevoEstado;
    }
  },
  [types.REMOVE_FROM_CART]: (state, action) => {
    const { id } = action.payload;
    const eliminarUno = state.carrito.find((item) => item.id === id);

    if (!eliminarUno) {
      return state;
    }

    if (eliminarUno.cantidad > 1) {
      const nuevoCarrito = state.carrito.map((item) =>
        item.id === id ? { ...item, cantidad: item.cantidad - 1 } : item
      );
      const nuevoTotal = state.total - eliminarUno.precio;
      const nuevoEstado = {
        ...state,
        carrito: nuevoCarrito,
        total: nuevoTotal,
      };
      updateLocalStorage(nuevoEstado);
      return nuevoEstado;
    } else {
      const nuevoCarrito = state.carrito.filter((item) => item.id !== id);
      const nuevoTotal =
        state.total - eliminarUno.precio * eliminarUno.cantidad;

      const nuevoEstado = {
        ...state,
        carrito: nuevoCarrito,
        total: nuevoTotal,
      };
      updateLocalStorage(nuevoEstado);
      return nuevoEstado;
    }
  },
  [types.REMOVE_ALL_FROM_CART]: (state, action) => {
    const { id } = action.payload;
    const eliminarTodo = state.carrito.find((item) => item.id === id);

    if (!eliminarTodo) {
      return state;
    }

    const nuevoEstado = {
      ...state,
      carrito: state.carrito.filter((item) => item.id !== id),
      total: state.total - eliminarTodo.precio * eliminarTodo.cantidad,
    };
    updateLocalStorage(nuevoEstado);
    return nuevoEstado;
  },
  [types.CLEAR_CART]: (state) => {
    const nuevoEstado = {
      ...state,
      carrito: [],
      total: 0,
    };
    updateLocalStorage(nuevoEstado);
    return nuevoEstado;
  },
};

export const carritoReducer = (state, action) => {
  const { type: actionType } = action;
  const estadoActualizado = actualizarEstado[actionType];
  return estadoActualizado ? estadoActualizado(state, action) : state;
};

export default carritoReducer;

/* 
export const carritoInicial = {
    carrito: [],
    total: 0
}  
export const carritoReducer = (state, action) => {
    const {type: actionType, payload: actionPayload} = action

    switch(actionType){
        case types.ADD_TO_CART:{
            const { id, nombre, precio, imagen, categoria } = actionPayload;
            const itemIndex = state.carrito.findIndex(item => item.id === id);

            if (itemIndex >= 0) {
                const nuevoCarrito = structuredClone(state.carrito);
                nuevoCarrito[itemIndex].cantidad += 1;
                const nuevoTotal = state.total + precio;
            
                return {
                    ...state,
                    carrito: nuevoCarrito,
                    total: nuevoTotal
                };
            } else {
                const productoNuevo = { id, nombre, precio, cantidad: 1, imagen, categoria };
                
                return {
                    ...state,
                    carrito: [...state.carrito, productoNuevo],
                    total: state.total + precio
                };
            } 
        }   
        case types.REMOVE_FROM_CART: {
            const { id } = actionPayload;
            const eliminarUno = state.carrito.find(item => item.id === id);
    
            if(!eliminarUno){
                return state;
            }

            if (eliminarUno.cantidad > 1) {
                    const nuevoCarrito = state.carrito.map(item =>
                    item.id === id ? { ...item, cantidad: item.cantidad - 1 } : item
                );
                const nuevoTotal = state.total - eliminarUno.precio;
                return {
                    ...state,
                    carrito: nuevoCarrito,
                    total: nuevoTotal
                };
            } else {
                const nuevoCarrito = state.carrito.filter(item => item.id !== id);
                const nuevoTotal = state.total - (eliminarUno.precio * eliminarUno.cantidad);

                return {
                    ...state,
                    carrito: nuevoCarrito,
                    total: nuevoTotal
                };
            }
        }
        case types.REMOVE_ALL_FROM_CART:{
            const {id} = actionPayload
            const eliminarTodo = state.carrito.find(item => item.id === id)
            
            if(!eliminarTodo){
                return state;
            }
            
            return{
                ...state,
                carrito: state.carrito.filter(item => item.id !== id),
                total: state.total - (eliminarTodo.precio * eliminarTodo.cantidad)
            }
        }
        case types.CLEAR_CART: {
            return {
                ...state,
                carrito: [],
                total: 0
            }        
        }
        default: return state;
    }
}
*/
