import React, { useEffect, useState, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import GetProducts from '../services/productos.services';
import { CarritoContext } from "../context/CarritoContext";
import Swal from "sweetalert2";
import "../Texto.css";

function Ofertas() {
    const { addCarrito } = useContext(CarritoContext);
    const [rows, setRows] = useState([]);
    const [groupSize, setGroupSize] = useState(3);
    const [error, setError] = useState(false);

    useEffect(() => {
        fetchData(); // Obtiene los productos al cargar el componente
        tamañoGrupo();
    }, []);

    const fetchData = async () => {
        const data = await GetProducts();
        if (data !== "Error de servidor") {
            setRows(data);
        }
        else {
            setError(true)
            Swal.fire({
                text: `${data}, por favor reintente más tarde.`,
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Aceptar",
                allowOutsideClick: false,
            })
        }
    };

    const tamañoGrupo = () => {
        if (window.innerWidth < 1077 && window.innerWidth > 100) {
            setGroupSize(2)
        }
        if (window.innerWidth < 768) {
            setGroupSize(1)
        }
    }

    // Función para agrupar las filas en grupos de tres
    const groupRows = (rows) => {
        const groupedRows = [];
        let index = 0;

        while (index < rows.length) {
            groupedRows.push(rows.slice(index, index + groupSize));
            index += groupSize;
        }

        return groupedRows;
    };

    const handleAddCarrito = (row) => {
        if(row[2] !== ''){
          addCarrito({
            id: row[0],
            nombre: row[1],
            precio: parseInt(row[2]),
            categoria: row[3],
            imagen: row[4]
          });
        }
        else{
          Swal.fire({
            text: 'Por el momento no es posible comprar este producto, intente más tarde',
            icon: "warning",
            showCancelButton: false,
            confirmButtonText: "Aceptar",
            allowOutsideClick: false,
          })
        }
      };

    const filteredRows = rows.filter(row => row[5].trim() !== "");

    const groupedRows = groupRows(filteredRows);

    return (
        <div>
            {error ? (<div>
                <h1 className='roboto-titulo'>
                    ERROR EN EL SERVIDOR
                </h1>
            </div>) :
                (<div style={{ maxWidth: '1200px', margin: 'auto' }}>
                    <h1 className="roboto-titulo">
                        ¡Artículos en oferta!
                    </h1>
                    <div style={{ marginTop: '20px', marginBottom: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
                        <Container fluid>
                            {groupedRows.map((group, groupIndex) => (
                                <Row key={groupIndex} className="justify-content-center" style={{ marginBottom: '20px', paddingLeft: '5px', paddingRight: '5px' }}>
                                    {group.map((row, rowIndex) => (
                                        <Col key={rowIndex} sm={12} md={6} lg={4} style={{ marginBottom: '20px', margin: 'auto' }}>
                                            <Card style={{ width: '22rem', margin: 'auto' }}>
                                                <Card.Img variant="top" src={row[4]} />
                                                <Card.Body className='roboto-texto'>
                                                    <Card.Title>{row[1]}</Card.Title>
                                                    <Card.Text>
                                                        Cod: {row[0]}
                                                    </Card.Text>
                                                    <Card.Text>
                                                        ${row[2]}
                                                    </Card.Text>
                                                    <Button variant="primary" onClick={() => handleAddCarrito(row)}>Añadir al carrito</Button>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            ))}
                        </Container>
                    </div>
                </div>)
            }
        </div>

    );
}

export default Ofertas;
