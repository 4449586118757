import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import "../Texto.css";

//imagenes de categorias
import imgBYL from '../images/categorias/bañoylavadero.jpg'
import imgC from '../images/categorias/cocina.jpg'
import imgTC from '../images/categorias/todas.jpg' 
import imgCE from '../images/categorias/cepillo.jpg' 
import imgPYJ from '../images/categorias/palasyjardin.jpg' 
import imgPLU from '../images/categorias/plumero.jpeg'
import imgESC from '../images/categorias/escoba.jpg'
import imgSYLV from '../images/categorias/limpiavidrios.jpg'
import imgTEXT from '../images/categorias/textiles.jpg'
import imgPLA from '../images/categorias/plasticos.jpg'
import imgMOPA from '../images/categorias/mopas.jpg'
import imgCABOS from '../images/categorias/cabos.jpg'
import imgALF from '../images/categorias/alfombra.jpg'
import imgVELA from '../images/categorias/velas.jpg'
import imgAUTO from '../images/categorias/automotor.jpg'

const Categorias = () => {
  return (
    <div style={{ maxWidth: '1200px', margin: 'auto' }}>
      <div style={{ marginTop: '20px', marginBottom: '20px', overflowX: 'auto', whiteSpace: 'nowrap' }}>
        <Container fluid className="d-flex flex-nowrap">
          <div style={{ display: 'inline-block', marginRight: '10px' }}>
            <Card style={{ width: '17rem' }}>
              <Card.Img variant="top" src={imgTC} style={{ height: '150px', width: '150px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
              <Card.Body className='roboto-texto'>
                <Card.Title>Todas las categorías</Card.Title>
                <Button variant="primary" href="#catalogo?category=empty">Ver productos</Button>
              </Card.Body>
            </Card>
          </div>

          <div style={{ display: 'inline-block', marginRight: '10px' }}>
            <Card style={{ width: '17rem', }}>
              <Card.Img variant="top" src={imgBYL} style={{ height: '150px', width: '150px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
              <Card.Body className='roboto-texto'>
                <Card.Title>Baño y Lavadero</Card.Title>
                <Button variant="primary" href="#catalogo?category=BAÑOYLAVADERO">Ver productos</Button>
              </Card.Body>
            </Card>
          </div>

          <div style={{ display: 'inline-block', marginRight: '10px' }}>
            <Card style={{ width: '17rem' }}>
              <Card.Img variant="top" src={imgCE} style={{ height: '150px', width: '150px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
              <Card.Body className='roboto-texto'>
                <Card.Title>Cepillos</Card.Title>
                <Button variant="primary" href="#catalogo?category=CEPILLOS">Ver productos</Button>
              </Card.Body>
            </Card>
          </div>

          <div style={{ display: 'inline-block', marginRight: '10px' }}>
            <Card style={{ width: '17rem' }}>
              <Card.Img variant="top" src={imgPYJ} style={{ height: '150px', width: '150px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
              <Card.Body className='roboto-texto'>
                <Card.Title>Palas y Jardín</Card.Title>
                <Button variant="primary" href="#catalogo?category=PALASYJARDIN">Ver productos</Button>
              </Card.Body>
            </Card>
          </div>

          <div style={{ display: 'inline-block', marginRight: '10px' }}>
            <Card style={{ width: '17rem' }}>
              <Card.Img variant="top" src={imgPLU} style={{ height: '150px', width: '150px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
              <Card.Body className='roboto-texto'>
                <Card.Title>Plumeros</Card.Title>
                <Button variant="primary" href="#catalogo?category=PLUMEROS">Ver productos</Button>
              </Card.Body>
            </Card>
          </div>

          <div style={{ display: 'inline-block', marginRight: '10px' }}>
            <Card style={{ width: '17rem' }}>
              <Card.Img variant="top" src={imgC} style={{ height: '150px', width: '150px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
              <Card.Body className='roboto-texto'>
                <Card.Title>Cocina</Card.Title>
                <Button variant="primary" href="#catalogo?category=COCINA">Ver productos</Button>
              </Card.Body>
            </Card>
          </div>

          <div style={{ display: 'inline-block', marginRight: '10px' }}>
            <Card style={{ width: '17rem' }}>
              <Card.Img variant="top" src={imgESC} style={{ height: '150px', width: '150px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
              <Card.Body className='roboto-texto'>
                <Card.Title>Escobas y Escobillones</Card.Title>
                <Button variant="primary" href="#catalogo?category=ESCOBASYESCOBILLONES">Ver productos</Button>
              </Card.Body>
            </Card>
          </div>

          <div style={{ display: 'inline-block', marginRight: '10px' }}>
            <Card style={{ width: '17rem' }}>
              <Card.Img variant="top" src={imgSYLV} style={{ height: '150px', width: '150px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
              <Card.Body className='roboto-texto'>
                <Card.Title>Secadores y Limpiavidrios</Card.Title>
                <Button variant="primary" href="#catalogo?category=SECADORESYLIMPIAVIDRIOS">Ver productos</Button>
              </Card.Body>
            </Card>
          </div>

          <div style={{ display: 'inline-block', marginRight: '10px' }}>
            <Card style={{ width: '17rem' }}>
              <Card.Img variant="top" src={imgTEXT} style={{ height: '150px', width: '150px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
              <Card.Body className='roboto-texto'>
                <Card.Title>Textiles</Card.Title>
                <Button variant="primary" href="#catalogo?category=TEXTILES">Ver productos</Button>
              </Card.Body>
            </Card>
          </div>

          <div style={{ display: 'inline-block', marginRight: '10px' }}>
            <Card style={{ width: '17rem' }}>
              <Card.Img variant="top" src={imgPLA} style={{ height: '150px', width: '150px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
              <Card.Body className='roboto-texto'>
                <Card.Title>Plásticos</Card.Title>
                <Button variant="primary" href="#catalogo?category=PLÁSTICOS">Ver productos</Button>
              </Card.Body>
            </Card>
          </div>

          <div style={{ display: 'inline-block', marginRight: '10px' }}>
            <Card style={{ width: '17rem' }}>
              <Card.Img variant="top" src={imgMOPA} style={{ height: '150px', width: '150px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
              <Card.Body className='roboto-texto'>
                <Card.Title>Mopas y Lampazos</Card.Title>
                <Button variant="primary" href="#catalogo?category=MOPASYLAMPAZOS">Ver productos</Button>
              </Card.Body>
            </Card>
          </div>

          <div style={{ display: 'inline-block', marginRight: '10px' }}>
            <Card style={{ width: '17rem' }}>
              <Card.Img variant="top" src={imgCABOS} style={{ height: '150px', width: '150px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
              <Card.Body className='roboto-texto'>
                <Card.Title>Cabos</Card.Title>
                <Button variant="primary" href="#catalogo?category=CABOS">Ver productos</Button>
              </Card.Body>
            </Card>
          </div>

          <div style={{ display: 'inline-block', marginRight: '10px' }}>
            <Card style={{ width: '17rem' }}>
              <Card.Img variant="top" src={imgALF} style={{ height: '150px', width: '150px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
              <Card.Body className='roboto-texto'>
                <Card.Title>Alfombras y Burletes</Card.Title>
                <Button variant="primary" href="#catalogo?category=ALFOMBRASYBURLETES">Ver productos</Button>
              </Card.Body>
            </Card>
          </div>

          <div style={{ display: 'inline-block', marginRight: '10px' }}>
            <Card style={{ width: '17rem' }}>
              <Card.Img variant="top" src={imgVELA} style={{ height: '150px', width: '150px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
              <Card.Body className='roboto-texto'>
                <Card.Title>Velas y Aromatizantes</Card.Title>
                <Button variant="primary" href="#catalogo?category=VELASYAROMATIZANTES">Ver productos</Button>
              </Card.Body>
            </Card>
          </div>
          <div style={{ display: 'inline-block', marginRight: '10px' }}>
            <Card style={{ width: '17rem' }}>
              <Card.Img variant="top" src={imgAUTO} style={{ height: '150px', width: '150px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
              <Card.Body className='roboto-texto'>
                <Card.Title>Línea Automotor</Card.Title>
                <Button variant="primary" href="#catalogo?category=LINEAAUTOMOTOR">Ver productos</Button>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Categorias;
