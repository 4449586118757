import { createContext, useReducer, useEffect } from "react";
import {
  types,
  carritoReducer,
  carritoInicial,
  updateLocalStorage,
} from "../reducers/carritoReducer";

export const CarritoContext = createContext();

function useCarritoReducer() {
  const [state, dispatch] = useReducer(carritoReducer, carritoInicial);

  useEffect(() => {
    updateLocalStorage(state.carrito);
  }, [state.carrito]);

  const addCarrito = (producto) => {
    dispatch({
      type: types.ADD_TO_CART,
      payload: producto,
    });
  };

  const removeCarrito = (producto, todo = false) => {
    if (todo) {
      dispatch({
        type: types.REMOVE_ALL_FROM_CART,
        payload: producto,
      });
    } else {
      dispatch({
        type: types.REMOVE_FROM_CART,
        payload: producto,
      });
    }
  };
  const clearCarrito = () =>
    dispatch({
      type: types.CLEAR_CART,
    });
  return [state, addCarrito, removeCarrito, clearCarrito];
}

export function CarritoContextProvider({ children }) {
  const [state, addCarrito, removeCarrito, clearCarrito] = useCarritoReducer();

  return (
    <CarritoContext.Provider
      value={{
        carrito: state.carrito,
        total: state.total,
        addCarrito,
        removeCarrito,
        clearCarrito,
      }}
    >
      {children}
    </CarritoContext.Provider>
  );
}
