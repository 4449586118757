import { Navbar, Container, Nav } from "react-bootstrap";

function Footer() {
  return (
    <>
      {/* Contenido del cuerpo de la página */}

      {/* Pie de página */}
      <Navbar
        variant="dark"
        style={{
          backgroundColor: "#137EB8",
          marginTop: "auto",
          paddingTop: "10px",
        }}
      >
        <Container>
          <Nav className="mx-auto">
            <Nav.Link
              href="http://www.freepik.com"
              style={{ marginBottom: 0, fontSize: "115%" }}
            >
              Images designed by Freepik
            </Nav.Link>
            <Nav.Link
              href="http://www.freepik.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginBottom: 0, fontSize: "115%" }}
            >
              Images designed by macrovector, brgfx, pikisuperstar, pch.vector,
              icon-icons
            </Nav.Link>
            <Nav.Link
              href="https://icon-icons.com/es"
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginBottom: 0, fontSize: "115%" }}
            >
              Icons designed by icon-icons
            </Nav.Link>
            <Nav.Link
              href="https://www.vecteezy.com/?utm_source=vecteezy-download&utm_medium=license-info-pdf&utm_campaign=license-info-document"
              style={{ marginBottom: 0, fontSize: "115%" }}
            >
              Vecteezy.com
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default Footer;
