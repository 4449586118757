import { useLocation } from "react-router-dom";
import Categorias from "./Categorias";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useEffect, useState, useContext } from "react";
import GetProducts from "../services/productos.services";
import { CarritoContext } from "../context/CarritoContext";
import Swal from "sweetalert2";
import "../Texto.css";

function Catalogo() {
  const { addCarrito } = useContext(CarritoContext);
  const [rows, setRows] = useState([]);
  const [groupSize, setGroupSize] = useState(3);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const category = params.get("category");
  const [catText, setCatText] = useState("");
  const [error, setError] = useState(false);
  const [specialMessage, setSpecialMessage] = useState('');

  useEffect(() => {
    fetchData(); // Obtiene los productos al cargar el catálogo
    tamañoGrupo();
    textoCategoria();
  }, []);

  useEffect(() => {
    textoCategoria();
  }, [category]);

  const fetchData = async () => {
    const data = await GetProducts();
    if (data !== "Error de servidor") {
      setRows(data);
    } else {
      setError(true);
      Swal.fire({
        text: `${data}, por favor reintente más tarde.`,
        icon: "error",
        showCancelButton: false,
        confirmButtonText: "Aceptar",
        allowOutsideClick: false,
      });
    }
  };

  const textoCategoria = () => {
    switch (category) {
      case "BAÑOYLAVADERO":
        setCatText("Baño y Lavadero:");
        setSpecialMessage('');
        break;
      case "CEPILLOS":
        setCatText("Cepillos:");
        setSpecialMessage('');
        break;
      case "PALASYJARDIN":
        setCatText("Palas y Jardín:");
        setSpecialMessage('');
        break;
      case "PLUMEROS":
        setCatText("Plumeros:");
        setSpecialMessage('');
        break;
      case "COCINA":
        setCatText("Cocina:");
        setSpecialMessage('');
        break;
      case "ESCOBASYESCOBILLONES":
        setCatText("Escobas y Escobillones:");
        setSpecialMessage('');
        break;
      case "SECADORESYLIMPIAVIDRIOS":
        setCatText("Secadores y Limpiavidrios:");
        setSpecialMessage('');
        break;
      case "TEXTILES":
        setCatText("Textiles:");
        setSpecialMessage('');
        break;
      case "PLÁSTICOS":
        setCatText("Plásticos:");
        setSpecialMessage('');
        break;
      case "MOPASYLAMPAZOS":
        setCatText("Mopas y Lampazos:");
        setSpecialMessage('');
        break;
      case "CABOS":
        setCatText("Cabos:");
        break;
      case "ALFOMBRASYBURLETES":
        setCatText("Alfombras y Burletes:");
        setSpecialMessage('');
        break;
      case "ALFOMBRASYBURLETES":
        setCatText("Alfombras y Burletes:");
        setSpecialMessage('');
        break;
      case "VELASYAROMATIZANTES":
        setCatText("Velas y Aromatizantes:");
        setSpecialMessage('Para los sahumerios, esencias, velas perfumadas y repuestos Make enviar al provedor un mensaje o audio aclarando la variedad requeridas referidas a los aromas');
        break;
      case "LINEAAUTOMOTOR":
        setCatText("Línea Automotor:");
        setSpecialMessage('');
        break;
      default:
        setCatText("Todas las categorías:");
        setSpecialMessage('Para los sahumerios, esencias, velas perfumadas y repuestos Make enviar al provedor un mensaje o audio aclarando la variedad requeridas referidas a los aromas');
        break;
    }
  };

  const tamañoGrupo = () => {
    if (window.innerWidth < 1077 && window.innerWidth > 100) {
      setGroupSize(2);
    }
    if (window.innerWidth < 768) {
      setGroupSize(1);
    }
  };

  const exceptiosTextiles = (row, precio) => {
    const validCodes = ["5015", "5016", "5019", "5506", "5507", "55071"];
    let adjustedPrice = precio;

    if (validCodes.includes(row[0])) {
      if (!isNaN(precio) && precio !== null && precio !== undefined) {
        adjustedPrice = precio * 12;
      } else {
        console.error("Precio no es un número válido");
        adjustedPrice = null;
      }
    }

    return { adjustedPrice, isSpecial: validCodes.includes(row[0]) };
  };

  const handleAddCarrito = (row) => {
    if (row[2] !== "") {
      const { adjustedPrice } = exceptiosTextiles(row, parseInt(row[2]));
      if (adjustedPrice !== null) {
        addCarrito({
          id: row[0],
          nombre: row[1],
          precio: adjustedPrice,
          categoria: row[3],
          imagen: row[4],
        });
      }
    } else {
      Swal.fire({
        text: "Por el momento no es posible comprar este producto, intente más tarde",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Aceptar",
        allowOutsideClick: false,
      });
    }
  };

  // Función para agrupar las filas en grupos de tres
  const groupRows = (rows) => {
    const groupedRows = [];
    let index = 0;

    while (index < rows.length) {
      groupedRows.push(rows.slice(index, index + groupSize));
      index += groupSize;
    }

    return groupedRows;
  };

  // Filtrar las filas por la categoría seleccionada (sin espacios) si la categoría no es "empty"
  const filteredRows =
    category && category !== "empty"
      ? rows.filter((row) => row[3].replace(/\s/g, "") === category)
      : rows;

  // Agrupar las filas filtradas
  const groupedRows = groupRows(filteredRows);

  return (
    <div>
      {error ? (
        <div>
          <h1 className="roboto-titulo">ERROR EN EL SERVIDOR</h1>
        </div>
      ) : (
        <>
          <Categorias />
          <div style={{ maxWidth: "1200px", margin: "auto" }}>
            <h1 className="roboto-titulo">{catText}</h1>
            {specialMessage && (
                <div style={{ marginTop: '20px', marginBottom: '20px', color: 'red', fontWeight: 'bold' }}>
                  {specialMessage}
                </div>
              )}
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Container fluid>
                {groupedRows.map((group, groupIndex) => (
                  <Row
                    key={groupIndex}
                    className="justify-content-center"
                    style={{
                      marginBottom: "20px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                  >
                    {group.map((row, rowIndex) => {
                      const { adjustedPrice, isSpecial } = exceptiosTextiles(
                        row,
                        parseInt(row[2])
                      );
                      const displayName = isSpecial
                        ? `${row[1]} (x12)`
                        : row[1];
                      return (
                        <Col
                          key={rowIndex}
                          sm={12}
                          md={6}
                          lg={4}
                          style={{ marginBottom: "20px", margin: "auto" }}
                        >
                          <Card style={{ width: "22rem", margin: "auto" }}>
                            <Card.Img variant="top" src={row[4]} />
                            <Card.Body className="roboto-texto">
                              <Card.Title>{displayName}</Card.Title>
                              <Card.Text>Cod: {row[0]}</Card.Text>
                              <Card.Text>${adjustedPrice}</Card.Text>
                              <Button
                                variant="primary"
                                onClick={() => handleAddCarrito(row)}
                              >
                                Añadir al carrito
                              </Button>
                            </Card.Body>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                ))}
              </Container>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Catalogo;
