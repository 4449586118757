import axios from "axios";

const GetProducts = async () => {
  try {
    //URL de la base de datos, segunda hoja del Google SpreadSheets
    const url = "https://docs.google.com/spreadsheets/d/e/2PACX-1vTY15BhGqsUpYUk-Tq1jFpqk8IXa5rc68Zg7r5xlTIie3DTUAn9QeINbNbdzmHfubEpE1mf7D0lvV-o/pub?gid=2057224851&single=true&output=csv";
    const response = await axios.get(url);
    const csvData = response.data;

    // Procesar el CSV en un formato adecuado (ej. JSON)
    const rows = csvData.split("\n").map((row) => {
      const columns = row.split(",");

      // Limpiar y combinar las columnas que contienen los valores del precio
      let price = `${columns[2]}`.replace(/["\r]/g, "").trim();

      // Asegurarse de que el precio tenga el formato correcto
      //price = price.replace(/(\d+)(\d{2})$/, '$1.$2');

      // Crear un array limpio con los valores adecuados
      const cleanedRow = [
        columns[0].trim(), // ID
        columns[1].trim(), // Nombre
        price, // Precio
        columns[3].trim(), // Categoría
        columns[4].trim(), // Link de imagen
        columns[5].trim(), // Promocion
      ];
      return cleanedRow;
    });

    // Filtrar filas vacías que puedan aparecer debido a la división
    const filteredRows = rows.filter((row) => row[0] && row[1]);

    if (filteredRows.length === 0) {
      return "Error de servidor";
    }

    return filteredRows;
  } catch (error) {
    return "Error de servidor";
  }
};

export default GetProducts;
