import { useContext, useState, useMemo } from "react";
import { CarritoContext } from "../context/CarritoContext";
import { Offcanvas, Badge, Card, Button, ButtonGroup } from "react-bootstrap";
import WhatsApp from "../images/whatsapp-icon.png";
import icon from "../images/carrito-icon.png";
import "../Carrito.css";
import "../Texto.css";

function isSpecialCode(id) {
  const validCodes = ["5015", "5016", "5019", "5506", "5507", "55071"];
  return validCodes.includes(id);
}

function Item({ id, nombre, precio, imagen, cantidad }) {
  const { addCarrito, removeCarrito } = useContext(CarritoContext);
  const displayName = isSpecialCode(id) ? `${nombre} (x12)` : nombre;
  return (
    <div className="item-container" style={{ marginBottom: "10px" }}>
      <Card className="item-card">
        <Card.Img variant="top" src={imagen} />
        <Card.Body className="item-body">
          <Card.Title>{displayName}</Card.Title> ${precio}
          <Card.Text> Cantidad: {cantidad} </Card.Text>
          <ButtonGroup className="boton-group" aria-label="Basic example">
            <Button
              style={{ backgroundColor: "#0e618d" }}
              onClick={() => addCarrito({ id, nombre, precio, imagen })}
              className="item-boton"
            >
              +
            </Button>
            <Button
              className="boton-item"
              style={{ backgroundColor: "#137eb8", paddingInline: "5pt" }}
              onClick={() => removeCarrito({ id }, true)}
            >
              Borrar Producto
            </Button>
            <Button
              style={{ backgroundColor: "#0e618d" }}
              className="boton-item"
              onClick={() => removeCarrito({ id })}
            >
              -
            </Button>
          </ButtonGroup>
        </Card.Body>
      </Card>
    </div>
  );
}

function Carrito() {
  const [show, setShow] = useState(false);
  const { carrito, clearCarrito, total } = useContext(CarritoContext);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const totalItems = carrito.reduce((sum, item) => sum + item.cantidad, 0);

  const mensaje = useMemo(() => {
    return carrito
      .reduce(
        (message, producto) =>
          message
            .concat(
              `* Cod: ${producto.id} - ${isSpecialCode(producto.id) ? `${producto.nombre} (x12)`: producto.nombre} --> ${producto.cantidad}\n`
            ),
        ``
      )
  }, [carrito, total]);

  const handleOrder = () => {
    const whatsappLink = `https://wa.me/5493516651761?text=${encodeURIComponent(
      mensaje
    )}`;
    window.open(whatsappLink, "_blank");
  };
  return (
    <>
      <div className="badge-container">
        <Badge
          bg="red"
          style={{
            position: "absolute",
            top: "-15px",
            right: "-10px",
            padding: "5px 5px",
            minWidth: "25px",
            minHeight: "25px",
            maxHeight: "35px",
            maxWidth: "35px",
            borderRadius: "50%",
            backgroundColor: "red",
            display: "flex",
            justifyContent: "center",
            color: "white",
            fontSize: "15px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {totalItems}
        </Badge>
        <img src={icon} alt="" className="carrito-icon" onClick={handleShow} />
      </div>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header
          closeButton
          style={{
            display: "flex",
            backgroundColor: "#137EB8",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={icon}
            alt=""
            style={{
              marginRight: "10px",
              height: "30px",
              width: "30px",
              position: "relative",
              top: "-1px",
              transform: "scale(1.1)",
            }}
            onClick={handleShow}
          />
          <Offcanvas.Title style={{ color: "white" }} className="roboto-titulo">
            Carrito
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="offcanvas-body">
          <ul>
            {carrito.map((producto) => (
              <Item key={producto.id} {...producto} />
            ))}
          </ul>
          <Button
            className="btnClearCarrito"
            style={{
              backgroundColor: "#137eb8",
              paddingInline: "5pt",
              fontFamily: "Roboto Condensed, sans-serif",
            }}
            onClick={() => clearCarrito()}
          >
            Borrar carrito
          </Button>
          <h2 className="total-title">Total: ${isNaN(total) ? "0" : total}</h2>
          {carrito.length > 0 && (
            <div onClick={handleOrder} className="enviar-pedido-container">
              <img
                src={WhatsApp}
                onClick={handleOrder}
                alt=""
                className="whatsapp-icon"
              />
              <h1 onClick={handleOrder} className="enviar-pedido-text">
                Enviar Pedido
              </h1>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Carrito;
